import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  async approve(e) {
    e.preventDefault();

    const batchId = this.element.dataset.id;

    const res = await patch(`/batches/${batchId}/status`, {
      body: {
        batch: {
          status: "approved",
        },
      },
    });

    if (res.redirected) {
      window.location.assign(res.response.url);
    } else {
      alert("Failed to approve batch.");
    }
  }

  async reject(e) {
    e.preventDefault();

    const batchId = this.element.dataset.id;

    const res = await patch(`/batches/${batchId}/status`, {
      body: {
        batch: {
          status: "rejected",
        },
      },
    });

    if (res.redirected) {
      window.location.assign(res.response.url);
    } else {
      alert("Failed to reject batch.");
    }
  }
}
